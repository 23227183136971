/* @import '~formiojs/dist/formio.full.min.css'; */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css");
@import '../../../node_modules/formiojs/dist/formio.full.min.css';

.col-form-label {
  font-size: 12px !important;
  padding-bottom: 3px !important;
}

.form-check-label {
  font-size: 12px !important;
}

.form-control {
  font-size: 12px !important;
}

.formio-editor-read-only-content {
  font-size: 12px !important;
  margin-left: -10px;
}

.form-group {
  margin-bottom: 10px !important;
}

.btn {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.btn-primary {
  background-color: #697B84 !important;
  border-color: #697B84 !important;
}

.btn-wizard-nav-submit {
  visibility: hidden!important;
}

.form-builder-panel {
  margin-top: 7px !important;
}

.form-builder-group-header {
  padding: 10px !important;
  background-color: #697B84 !important;
}

.formcomponent {
  background-color: #9DD2AF !important;
  border-color: #9DD2AF !important;
  color: #697B84 !important;
}

.formcomponents {
  padding-left: 5px !important;
  padding-right: 0 !important;
}

.builder-group-button {
  color: white !important;
}

.formio-component-columns {
  margin-top: -10px !important;
}

.formio-component-panel {
  padding-top: 10px !important;
}

.card-header {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  color: #697B84;
  font-weight: 500;
}

.card-body {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.page-item>button {
  font-size: 12px !important;
  color: #697B84 !important;
  border-color: #697B84 !important;
}

.page-item.active>button {
  background-color: #697B84 !important;
  color: white !important;
}

ul.pagination {
  justify-content: center;
}

.formio-form > div > nav > ul.pagination {
  justify-content: center;
}
.ol-zoom-in,
.ol-zoom-out,
.ol-zoomslider-thumb,
.ol-full-screen-false,
.styled-ol-control {
  background-color: #697B84 !important;
  color: white !important;
}

.ol-attribution button {
  background-color: #697B84 !important;
  color: white !important;
}